import React, { useEffect, useState, useRef, useCallback } from "react";
import uuid from 'react-uuid';
import {
  Routes,
  Route,
  useParams,
  Outlet,
  useNavigate,
  Link,
} from "react-router-dom";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
  Tooltip,
  Button,
  Card,
  Divider,
  CardContent,
  Checkbox,
  Collapse,
  Container,
  Grid,
  IconButton,
  Modal,
  Typography,
  CircularProgress,
  CardHeader,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormControlLabel,
  Tabs,
  Tab,
  LinearProgress,
  Fab,
  Switch,
  Drawer,
  CardActionArea,
  Badge,
  Chip,
  Avatar,
  Radio,
  RadioGroup,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import SortIcon from '@mui/icons-material/Sort';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Alert from '@mui/material/Alert';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Signature from './Signature'

import useSound from 'use-sound';

import successSfx from '../sounds/success.wav';
import errorSfx from '../sounds/error.wav';

import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useCookies } from 'react-cookie';
import { CheckCircleOutline, Face, FaceRetouchingNatural, HelpOutlineOutlined, InfoOutlined, KeyboardArrowDown, KeyboardArrowUp, Print, Redeem, SettingsOverscanSharp, SettingsRemoteSharp, ViewTimeline } from "@mui/icons-material";
import CustomerTransactions from "./CustomerTransactions";
import { DataGrid } from "@mui/x-data-grid";
import CreditManagementLight from "./CreditManagementLight";
import SeparateEscrowCheckout, { AuslagenWidget } from "./SeparateEscrowCheckout";
import LagerplatzDetails from './LagerplatzDetails'
import CustomerRegistration from "./CustomerRegistration";
import TwintPaymentButton from "./TwintPaymentButton";

const socketUrl = 'wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';


const CheckoutLayout = () => {
  return (
    <>
      <Typography variant="h6">Sendungsabholung</Typography>
      <Outlet />
    </>
  )
}


const Checkout = ({ posConfig }) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<CheckoutLayout />}>
          <Route index element={<CheckoutHome posConfig={posConfig} />} />
          <Route path=":customerId" element={<CheckoutCustomer posConfig={posConfig} />} />
        </Route>
      </Routes>
    </>
  )
}

export default Checkout


const CheckoutHome = ({ posConfig }) => {

  const [cookies] = useCookies(['secret', 'station', 'staff']);
  const { sendMessage, readyState } = useWebSocket(socketUrl,
    {
      queryParams: {
        station: cookies.station,
        secret: cookies.secret,
        staff: cookies.staff
      },
      onMessage: (message) => {},
      shouldReconnect: (_closeEvent) => true,
      retryOnError: true,
      onClose: () => console.log('closing'),
      onError: (err) => console.log('error', err),
      onReconnectStop: (err) => console.log('recon stop', err),
    }
  );
  const sendProfiling = (data) => {
    sendMessage(JSON.stringify({ task: 'sendProfiling', station: posConfig.connection.station, staff: cookies.staff, data }))
  }


  useEffect(()=>{
    sendProfiling({
      task: 'CHECKOUT',
      duration: null
    })
  },[])

  const navigate = useNavigate()

  const handlerChange = (val) => {
    navigate(val + "");
  }

  //<ScannerInput setValue={handlerChange} />
  //<Button onClick={handlerSubmit}>Suchen</Button>
  return (

    <>
      <Typography variant="h3" gutterBottom>Kundennummer</Typography>
      <Box>
        <SearchField posConfig={posConfig} onSelect={handlerChange} />
      </Box>
    </>
  )
}


const CheckoutCustomer = ({ posConfig }) => {



  const emptyUserMeta = {
    firstname: "",
    lastname: "",
    place: "",
    zip: "",
    street: "",
    company: "",
    country: "",
    salutation: "",
    address: "",
    phone_private: "",
    phone_mobile: "",
  }
  const { customerId } = useParams()

  const [loading, setLoading] = useState(true)

  const [newShipments, setNewShipments] = useState([])
  const [oldShipments, setOldShipments] = useState([])
  const [selectedShipments, setSelectedShipments] = useState([]);
  const [procurements, setProcurements] = useState([])
  const [creditRecords, setCreditRecords] = useState([])
  const [creditAmount, setCreditAmount] = useState()
  const [userdata, setUserdata] = useState(emptyUserMeta)
  const [notifications, setNotifications] = useState([])
  const [notificationsPopup, setNotificationsPopup] = useState([])
  const [outlistHistory, setOutlistHistory] = useState([])
  const [openPopup, setOpenPopup] = useState()
  const [paymentMethod, setPaymentMethod] = useState()
  const [secondPaymentMethod, setSecondPaymentMethod] = useState()



  const [respCalculateBonus, setRespCalculateBonus] = useState();
  const [respCustomerCouponCodes, setRespCustomerCouponCodes] = useState();
  const [respAddFunds, setRespAddFunds] = useState();

  const [customerTransactions, setCustomerTransactions] = useState([])

  const [abholerID, setAbholerID] = useState(false)
  const [abholerText, setAbholerText] = useState(false)

  const [creditsConsumed, setCreditsConsumed] = useState(0)
  const [paymentDetails, setPaymentDetails] = useState(false)

  const [selected, setSelected] = useState([])
  const [selectedErrors, setSelectedErrors] = useState([])

  const [profiling, setProfiling] = useState(null);

  const wpMetaParser = (arr) => {
    return {
      firstname: arr.filter(e => e.meta_key === 'first_name')[0]?.meta_value || "",
      lastname: arr.filter(e => e.meta_key === 'last_name')[0]?.meta_value || "",
      place: arr.filter(e => e.meta_key === 'mps_city')[0]?.meta_value || "",
      zip: arr.filter(e => e.meta_key === 'mps_zip')[0]?.meta_value || "",
      street: arr.filter(e => e.meta_key === 'mps_address')[0]?.meta_value || "",
      company: arr.filter(e => e.meta_key === 'mps_company')[0]?.meta_value || "",
      country: arr.filter(e => e.meta_key === 'mps_country')[0]?.meta_value || "",
      salutation: arr.filter(e => e.meta_key === 'mps_salutation')[0]?.meta_value || "",
      address: arr.filter(e => e.meta_key === 'mps_address')[0]?.meta_value || "",
      phone_private: arr.filter(e => e.meta_key === 'mps_phone_private')[0]?.meta_value || "",
      phone_mobile: arr.filter(e => e.meta_key === 'mps_phone_mobile')[0]?.meta_value || "",
      birthdate: arr.filter(e => e.meta_key === 'mps_birthday')[0]?.meta_value || "",
      kdnr: arr[0].user_id
    }
  }

  const [cookies] = useCookies(['secret', 'station', 'staff']);

  const { sendMessage, readyState } = useWebSocket(socketUrl,
    {
      queryParams: {
        station: posConfig.connection.station,
        secret: cookies.secret,
        staff: cookies.staff
      },
      onMessage: (message) => handleWebsocketEvent(message),
      shouldReconnect: (_closeEvent) => true,
      retryOnError: true,
      onClose: () => console.log('closing'),
      onError: (err) => console.log('error', err),
      onReconnectStop: (err) => console.log('recon stop', err),
    }
  );




  const loadData = useCallback(() => {
    sendMessage(JSON.stringify({ task: 'reqCustomer', station: posConfig.connection.station, staff: cookies.staff, id: customerId }))
  }, [sendMessage, customerId, posConfig.connection.station, cookies.staff]);


  const updateNotification = (notification) => {
    sendMessage(JSON.stringify({ task: 'updateNotification', station: posConfig.connection.station, staff: cookies.staff, id: customerId, notification }))
  }

  const updateStammdaten = (userdata) => {
    sendMessage(JSON.stringify({ task: 'updateStammdaten', station: posConfig.connection.station, staff: cookies.staff, id: customerId, userdata }))
  }

  const getKundenkartePDF = () => {
    sendMessage(JSON.stringify({ task: 'getKundenkartePDF', station: posConfig.connection.station, staff: cookies.staff, id: customerId }))
  }

  const getStammdatenblattPDF = () => {
    sendMessage(JSON.stringify({ task: 'getStammdatenblattPDF', station: posConfig.connection.station, staff: cookies.staff, id: customerId }))
  }

  const createNewProcure = (procure) => {
    sendMessage(JSON.stringify({ task: 'createNewProcure', station: posConfig.connection.station, staff: cookies.staff, id: customerId, procure }))
  }

  const updateProcure = (procure) => {
    sendMessage(JSON.stringify({ task: 'updateProcure', station: posConfig.connection.station, staff: cookies.staff, id: customerId, procure }))
  }

  const pickupMonitorMessage = () => {
    sendMessage(JSON.stringify({ task: 'pickupMonitorMessage', station: posConfig.connection.station, staff: cookies.staff, customerId, customerName: userdata.firstname + ' ' + userdata.lastname, pickupList }))
  }

  const getOutlistHistory = () => {
    sendMessage(JSON.stringify({ task: 'getOutlistHistory', station: posConfig.connection.station, staff: cookies.staff, id: customerId }))
  }

  const getAbholbelegFile = (s3key) => {
    sendMessage(JSON.stringify({ task: 'getAbholbelegFile', station: posConfig.connection.station, staff: cookies.staff, id: customerId, s3key }))
  }

  const addFunds = (newFunds) => {
    sendMessage(JSON.stringify({ task: 'addFunds', station: posConfig.connection.station, staff: cookies.staff, id: customerId, newFunds }))
  }

  const calculateBonus = (amount, code) => {
    sendMessage(JSON.stringify({ task: 'calculateBonus', station: posConfig.connection.station, staff: cookies.staff, id: customerId, amount, code }))
  }

  const getCustomerTransactions = (amount, code) => {
    sendMessage(JSON.stringify({ task: 'getCustomerTransactions', station: posConfig.connection.station, staff: cookies.staff, id: customerId }))
  }

  const getCustomerCouponCodes = () => {
    sendMessage(JSON.stringify({ task: 'getCustomerCouponCodes', station: posConfig.connection.station, staff: cookies.staff, id: customerId }))
  }

  const sendProfiling = (data) => {
    
    sendMessage(JSON.stringify({ task: 'sendProfiling', station: posConfig.connection.station, staff: cookies.staff, id: customerId, data }))

  }

  const navigate = useNavigate()


  useEffect(() => {
    loadData();
  }, [loadData, customerId])

  const [isActivated, setIsActivated] = useState(false);
  const handleWebsocketEvent = (lastMessage) => {

    const msg = JSON.parse(lastMessage.data)
    console.log(msg)

    if (msg.task === 'respCustomer' && msg.exists) {
      const { olds, news, procure, wpusermeta, wpuser, funds, notifications, history, isActivated } = msg

      if (wpuser[0].ID + "" !== customerId + "") {
        alert('Achtung! Mehrere Tabs geöffnet!')
        navigate('/')
      }

      setIsActivated(isActivated);
      setNotifications(notifications);

      setNotificationsPopup(notifications.filter(e => e.popup === 1).filter(e => e.status === 0))

      setOpenPopup(notifications.filter(e => e.popup === 1).filter(e => e.status === 0).length > 0)
      setNewShipments(news)
      setOldShipments(olds)
      setCustomerTransactions(history)
      setProcurements(procure)
      setCreditRecords([])
      setOutlistHistory([])
      setRespCustomerCouponCodes();
      setCreditAmount(funds);
      setProfiling({
        type: 'checkout',
        loadCustomer: Date.now()
      })
      console.log({ wpusermeta, wpuser })
      setUserdata(
        {
          ...wpMetaParser(wpusermeta),
          email: wpuser[0].user_email
        })
      setLoading(false)
    }

    if (msg.task === 'respCustomer' && msg.exists === false) {
      alert('Kunde nicht gefunden')
      navigate('/checkout')
    }

    if (msg.task === 'respNotifications') {
      const { notifications } = msg
      setNotifications(notifications)
      setNotificationsPopup(notifications.filter(e => e.popup === 1).filter(e => e.status === 0))
    }

    if (msg.task === 'respUpdateUserdata') {
      const { wpusermeta, wpuser } = msg
      setUserdata(
        {
          ...wpMetaParser(wpusermeta),
          email: wpuser[0].user_email
        })
    }

    if (msg.task === 'respKundenkartePDF') {
      const { pdfUrl } = msg
      window.open(pdfUrl)
    }

    if (msg.task === 'respStammdatenblattPDF') {
      const { pdfUrl } = msg
      window.open(pdfUrl)
    }


    if (msg.task === 'respCreateNewProcure' || msg.task === 'respUpdateProcure') {
      const { procure } = msg
      setProcurements(procure)
    }
    if (msg.task === 'respGetOutlistHistory') {
      const { historie } = msg
      setOutlistHistory(historie || [])
    }

    if (msg.task === 'respAbholbelegFile') {
      const { url } = msg
      window.open(url)
    }

    if (msg.task === 'respCalculateBonus') {
      setRespCalculateBonus(msg)
    }

    if (msg.task === 'respCustomerCouponCodes') {
      setRespCustomerCouponCodes(msg);
    }

    if (msg.task === 'respAddFunds') {
      setRespAddFunds(msg)
    }

    if (msg.task === 'respCustomerTransactions') {
      const { history, funds } = msg;
      setCreditAmount(funds)
      setCustomerTransactions(history)
    }

  }


  useEffect(() => {
    setSelectedShipments(newShipments.filter(e => selected.includes(e.id)))
  }, [newShipments, selected])


  const handlerSelect = (checked, id) => {

    if (checked) {
      setPickupList(pickupList.filter(e => e.id !== id))
      setSelected([...selected.filter(e => e !== id), id])
    } else {
      setSelected(selected.filter(e => e !== id))
    }
  }

  const [playError] = useSound(errorSfx);
  const [playSuccess] = useSound(successSfx);

  const handlerSelectScanner = (id) => {

    const shipment = newShipments.filter(e => e.id === id)
    if (shipment.length) {
      if (selected.filter(e => e === id).length) {
        setSelectedErrors([...selectedErrors, { type: 'ALREADY_SCANNED', id }])
        playError()
      } else {
        setSelected([...selected, id])
        playSuccess()
      }
    } else {
      setSelectedErrors([...selectedErrors, { type: 'NOT_IN_SHIPMENT_LIST', id }])
      playError()
    }

  }

  const handlerSelectAbholer = ({ abholerID, abholerText }) => {
    setAbholerID(abholerID)
    setAbholerText(abholerText)
  }

  const handlerPaymentSucceeded = (data) => {
    const { payedPrepaid, paymentMethod, secondPaymentMethod } = data;
    setCreditsConsumed(payedPrepaid)
    setPaymentMethod(paymentMethod)
    setSecondPaymentMethod(secondPaymentMethod)
    setPaymentDetails(data)
  }

  const [sortDir, setSortDir] = useState('ASC');
  const [sortKey, setSortKey] = useState(false);

  const handleSort = (key) => {
    if (key === sortKey) {
      setSortDir(sortDir === 'ASC' ? 'DESC' : 'ASC')
    } else {
      setSortKey(key)
      setSortDir('ASC')
    }

  }


  const shipmentSortFunction = (a, b) => {
    if (sortKey) {
      if (sortDir === 'ASC') {
        return a[sortKey].localeCompare(b[sortKey])
      } else {
        return b[sortKey].localeCompare(a[sortKey])
      }
    }
    return 1;
  }



  const [sort2Dir, setSort2Dir] = useState('ASC');
  const [sort2Key, setSort2Key] = useState('klasse');

  const handleSort2 = (key) => {
    if (key === sort2Key) {
      setSort2Dir(sort2Dir === 'ASC' ? 'DESC' : 'ASC')
    } else {
      setSort2Key(key)
      setSort2Dir('ASC')
    }

  }

  const shipmentSort2Function = (a, b) => {
    if (sort2Key) {
      if (sort2Dir === 'ASC') {
        return a[sort2Key].localeCompare(b[sort2Key])
      } else {
        return b[sort2Key].localeCompare(a[sort2Key])
      }
    }
    return 1;
  }





  useEffect(() => {
    if (window) window?.scrollTo(0, document?.body?.scrollHeight);
  }, [paymentDetails])


  const [pickupList, setPickupList] = useState([])
  const selectPickupHandler = (pickupRequest, selected) => {
    if (selected) {
      setPickupList([
        ...pickupList.filter(e => e.id !== pickupRequest.id),
        pickupRequest
      ])
    } else {
      setPickupList(pickupList.filter(e => e.id !== pickupRequest.id))
    }
  }
  const handleDeselectAllPickupList = () => {
    setPickupList([])


  }
  useEffect(() => {
    if (abholerText) {
      pickupMonitorMessage();
    }
  }, [pickupList])

  useEffect(() => {

    setPickupList(newShipments.filter(e => !selected.includes(e.id)).map(e => {
      const {
        id,
        klasse,
        spedition,
        lager_name
      } = e
      return {
        id,
        klasse,
        spedition,
        lager_name
      }
    }))

  }, [newShipments])

  const [manualScanLog, setManualScanLog] = useState({});

  const manualLogging = (k, v) => {
    setManualScanLog({ ...manualScanLog, [k]: v })
  };

  useEffect(() => {
    let intervalId;

    if (profiling) {
      // If x is true, start the interval to call the function every 5 seconds
      intervalId = setInterval(() => {
        sendProfiling({
          task: 'CHECKOUT',
          duration: Date.now() - profiling.loadCustomer
        })
      }, 5000);
    }

    // Cleanup the interval if x becomes false or if the component unmounts
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [profiling]); 

  return (
    <>
      <Fab color="primary" aria-label="add" sx={{ position: 'fixed', left: '100px', bottom: '50px' }} onClick={() => {
        if (window) window?.scrollTo(0, document?.body?.scrollHeight);
      }}>
        <ArrowDownwardIcon />
      </Fab>

      <Modal open={notificationsPopup.length > 0} >
        <Container disableGutters={true}>
          <Card sx={{ p: 2, mt: 4, width: '100%' }}>
            {notificationsPopup.map((e, i) => <Notification updateNotification={updateNotification} notificationsPopup={notificationsPopup} setNotificationsPopup={setNotificationsPopup} key={i} userdata={userdata} {...e} />)}
          </Card>
        </Container>
      </Modal>
      <Box sx={{ display: 'flex', justifyContent: "space-between" }}>

        <Typography variant="h5" gutterBottom>
          {customerId} - {userdata.firstname} {userdata.lastname}
        </Typography>
        <Box sx={{ display: 'flex', mb: 2 }}>
          {isNaN(creditAmount) === false && <>
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', p: 1, mr: 2, border: 'solid 1px darkgray', background: creditAmount < 100 ? '#ffccbc' : '#dcedc8' }}>
              <AccountBalanceWalletIcon sx={{ mr: 1 }} />
              {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((creditAmount / 100))}
            </Box>
          </>}
          <HistorieButton getAbholbelegFile={getAbholbelegFile} outlistHistory={outlistHistory} getOutlistHistory={getOutlistHistory} customerTransactions={customerTransactions} userdata={userdata} creditAmount={creditAmount} />
          <StammdatenblattButton posConfig={posConfig} getCustomerCouponCodes={getCustomerCouponCodes} respCustomerCouponCodes={respCustomerCouponCodes} getCustomerTransactions={getCustomerTransactions} addFunds={addFunds} calculateBonus={calculateBonus} respCalculateBonus={respCalculateBonus} respAddFunds={respAddFunds} customerTransactions={customerTransactions} notifications={notifications} updateNotification={updateNotification} getKundenkartePDF={getKundenkartePDF} getStammdatenblattPDF={getStammdatenblattPDF} updateStammdaten={updateStammdaten} userdata={userdata} creditAmount={creditAmount} />
        </Box>
      </Box>
      {loading && <><LinearProgress /></>}
      {!loading &&
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box sx={{ mb: 2 }}>
              <CheckoutPerson isActivated={isActivated} userdata={userdata} setValue={handlerSelectAbholer} procurements={procurements} createNewProcure={createNewProcure} updateProcure={updateProcure} />
            </Box>
          </Grid>

          {!!abholerText && <>
            <Grid item xs={12}>
              <ScannerInput setValue={handlerSelectScanner} />
              <ScannerErrors errors={selectedErrors} setErrors={setSelectedErrors} />
            </Grid>

            <Grid item xs={12}>
              <SeparateEscrowCheckout posConfig={posConfig} kdnr={customerId} signatureName={abholerText || (userdata.firstname + " " + userdata.lastname)} />
            </Grid>
            <Grid item xs={7}>
              <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table sx={{ minWidth: 640 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell><Button variant="outlined" size="small" onClick={handleDeselectAllPickupList}>Entf.</Button></TableCell>
                      <TableCell>MPS ID<IconButton onClick={() => handleSort('id')}><SortIcon /></IconButton></TableCell>
                      <TableCell>Klasse<IconButton onClick={() => handleSort('klasse')}><SortIcon /></IconButton></TableCell>
                      <TableCell>Lager ID<IconButton onClick={() => handleSort('lager_name')}><SortIcon /></IconButton></TableCell>
                      <TableCell>Spedition<IconButton onClick={() => handleSort('spedition')}><SortIcon /></IconButton></TableCell>
                      <TableCell>Preis</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {newShipments.filter(e => !selected.includes(e.id)).sort(shipmentSortFunction).map((e, i) => <NewShipment key={i} data={e} selectHandler={handlerSelect} manualLogging={manualLogging} selected={selected.includes(e.id)} selectPickupHandler={selectPickupHandler} pickupList={pickupList} />)}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={5}>
              <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table sx={{ minWidth: 350 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#{selected.length}</TableCell>
                      <TableCell>MPS ID<IconButton onClick={() => handleSort2('id')}><SortIcon /></IconButton></TableCell>
                      <TableCell>Klasse<IconButton onClick={() => handleSort2('klasse')}><SortIcon /></IconButton></TableCell>
                      <TableCell align="right">Preis</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {newShipments.filter(e => selected.includes(e.id)).sort(shipmentSort2Function).map((e, i) => <SelectedShipment key={i} data={e} selectHandler={handlerSelect} selected={selected.includes(e.id)} />)}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>}
          {!!selectedShipments.length && <>

            <Grid item xs={12}>

              <Box sx={{ mb: 4 }}>
                <Payment signature={<>{!!paymentDetails && <Signature setProfiling={setProfiling} profiling={profiling} shipments={selectedShipments} userdata={userdata} paymentMethod={paymentMethod} secondPaymentMethod={secondPaymentMethod} manualScanLog={manualScanLog} signatureName={abholerText} consumedPrepaid={creditsConsumed} />}</>} setValue={handlerPaymentSucceeded} shipments={selectedShipments} userdata={userdata} signatureName={abholerText} creditAmount={creditAmount} />
              </Box>

            </Grid>
          </>
          }

        </Grid>}

    </>
  )
}



const NewShipment = ({ data, selected, selectHandler, selectPickupHandler, pickupList, manualLogging }) => {

  const { id, klasse, preis, spedition, lager_name, creationdate, absender } = data

  const [checked, setChecked] = useState(!!pickupList.filter(e => e.id === id).length)

  useEffect(() => {

    selectPickupHandler({
      id,
      klasse,
      spedition,
      lager_name,
      absender,
    }, checked)

  }, [checked])

  useEffect(() => {
    setChecked(!!pickupList.filter(e => e.id === id).length)
  }, [pickupList])

  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>
        <Checkbox sx={{ mr: 2 }} onClick={(evt) => { setChecked(evt.target.checked) }} checked={checked} />
      </TableCell>
      <TableCell component="th" scope="row" sx={{ userSelect: 'none' }}><Tooltip arrow title={<Box>Auf Lager seit: {creationdate}</Box>}><Box>{id}</Box></Tooltip></TableCell>
      <TableCell>{klasse}</TableCell>
      <TableCell>{lager_name} <ShipmentHistoryButton id={id} /></TableCell>
      <TableCell>{spedition}</TableCell>
      <TableCell align="right">{preis}</TableCell>
      <TableCell><ConfirmManualCheckout manualLogging={manualLogging} checked={selected} onClickHandler={selectHandler} id={id} /></TableCell>
    </TableRow>
  )
}

const ShipmentHistoryButton = ({ id }) => {

  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton onClick={() => { setOpen(true) }}><ViewTimeline /></IconButton>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Container maxWidth="md">
          <Paper sx={{ p: 4, m: 4 }}>
            <LagerplatzDetails sid={id} />
          </Paper>
        </Container>
      </Modal>
    </>
  );
};
export { ShipmentHistoryButton };

const SelectedShipment = ({ data, selected, selectHandler }) => {

  const { id, klasse, sendungsnummer, preis, abholen_bis, lager_id, lager_name } = data

  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>
        <Button variant="outlined" onClick={() => {
          const confirmed = window?.confirm("Bereits gescannte Sendung zurücknehmen?");
          if (confirmed) selectHandler(false, id)
        }}>&lt;</Button>
      </TableCell>
      <TableCell component="th" scope="row" sx={{ userSelect: 'none' }}>{id}</TableCell>
      <TableCell>{klasse}</TableCell>
      <TableCell align="right">{preis}</TableCell>
    </TableRow>
  )
}

const ConfirmManualCheckout = ({ manualLogging, onClickHandler, id, checked: selected, onPickupClickHandler }) => {

  const [checked, setChecked] = useState(false);
  const [comment, setComment] = useState("");
  const [error, setError] = useState("");

  //<Checkbox checked={selected} onClick={(evt)=>{selectHandler(evt, id)}} />

  const handleClick = () => {
    if (comment.length > 10) {
      manualLogging(id, comment)
      setChecked(false)
      onClickHandler(true, id)
    } else {
      setError('Kommentar zu kurz');
    }
  }

  const handleCancel = () => {
    setChecked(false)
  }

  return (
    <>
      <Modal open={checked}>
        <Container disableGutters={true}>
          <Card sx={{ p: 2, mt: '50%' }}>
            <Box sx={{ mb: 2 }}>
              <TextField onChange={(evt) => { setComment(evt.target.value) }} sx={{ width: '400px' }} label="Weshalb ist ein Scan nicht möglich?" />
            </Box>
            {error && comment.length <= 10 && <Alert severity="warning" sx={{ mb: 2 }}>{error}</Alert>}
            <Button onClick={handleClick} variant="contained">Speichern</Button>
            <Button onClick={handleCancel} sx={{ ml: 2 }} variant="outlined">Abbrechen</Button>
          </Card>
        </Container>
      </Modal>
      <Button variant="outlined" onClick={() => setChecked(true)}>&gt;</Button>
    </>
  )
}


const SearchField = ({ posConfig, onSelect, PaperComponent, paperSx, noAutofocus }) => {

  const [temp, setTemp] = useState("");
  const [search, setSearch] = useState(false)

  const [mode, setMode] = useState("kdnr")

  const [lastRequestId, setLastRequestId] = useState(0)
  const [searchResults, setSearchResults] = useState([])



  const [cookies] = useCookies(['secret', 'station', 'staff'])

  const { sendMessage, readyState } = useWebSocket(socketUrl,
    {
      queryParams: {
        station: posConfig.connection.station,
        secret: cookies.secret,
        staff: cookies.staff
      },
      onMessage: (message) => handleWebsocketEvent(message),
      shouldReconnect: (_closeEvent) => true,
      retryOnError: true,
      onClose: () => console.log('closing'),
      onError: (err) => console.log('error', err),
      onReconnectStop: (err) => console.log('recon stop', err),
    }
  );

  const handleWebsocketEvent = (lastMessage) => {

    const msg = JSON.parse(lastMessage.data)

    if (msg.task === 'respSearchKdnrByTerm') {
      const { results, requestId } = msg
      if (requestId === lastRequestId) {
        setSearchResults(results)
      }
    }
  }

  const searchKdnrByTerm = (searchTerm, thisRqId) => {
    sendMessage(JSON.stringify({ task: 'searchKdnrByTerm', station: posConfig.connection.station, staff: cookies.staff, searchTerm, requestId: thisRqId }))
  }

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (temp === "") {
        setMode('kdnr')
        setSearch(false)
        setSearchResults([])
      } else {
        setMode('name')
        setSearch(temp)
        const thisRqId = uuid()
        setLastRequestId(thisRqId)
        searchKdnrByTerm(temp, thisRqId)
      }
    }, 250);
    return () => clearTimeout(timeOutId);
  }, [temp])

  const handlerKeyPress = (evt) => {
    if (evt.keyCode === 13) {
      if (!isNaN(temp)) {
        onSelect(temp)
      }
    }
  }
  const kdnrInput = useRef(null);

  useEffect(() => {
    if (!noAutofocus) {
      kdnrInput.current.focus();
    }
  }, [])

  const PaperComp = PaperComponent || Paper;

  return (
    <PaperComp sx={paperSx || { mb: 2, p: 4 }} onMouseEnter={() => {
      kdnrInput.current.focus();
    }}>
      <Autocomplete
        disablePortal
        autoHighlight
        freeSolo
        id="combo-box-kdnr-searchterm"
        options={searchResults.map(e => ({
          label: e.ID + ' - ' + e.display_name + ' (' + e.user_email + ')',
          id: e.ID
        }))}
        sx={{ width: '100%' }}
        value={temp}
        onInputChange={(evt, value, id) => { setTemp(value) }}
        onChange={(a, b) => {
          if (b?.id) {
            onSelect(b.id)
          }
        }}
        renderInput={(params) => <TextField variant="outlined" inputRef={kdnrInput} {...params} label="Suche nach Kunde" />}
        onKeyDown={handlerKeyPress}
        noOptionsText={"Suche nach Name oder Email"}
      />
    </PaperComp>
  )
}

export { SearchField };

const SearchFieldX = ({ onChange }) => {


  const [value, setValue] = useState("");
  const [mode, setMode] = useState('kdnr')
  const [search, setSearch] = useState(false)

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (isNaN(value)) {
        setMode('name')
        setSearch(value)
      } else {
        setMode('kdnr')
        setSearch(false)
      }
    }, 300);
    return () => clearTimeout(timeOutId);
  }, [value])




  const kdnrInput = useRef(null)

  useEffect(() => {
    kdnrInput.current.focus()
  }, [])


  // check for "x" in begin and end (barcode)
  const checkX = (str) => {
    let hadX = false;
    if (str[0] === "x" && str[str.length - 1] === "x") {
      hadX = true;
      str = str.slice(1, -1);
    }
    return {
      input: str,
      hadX: hadX
    };
  }

  const handlerKeyPress = (evt) => {
    if (evt.keyCode === 13) {

      const { input, hadX } = checkX(evt.target.value)

      onChange(input)
      setValue("")
      kdnrInput.current.focus()
    }
  }


  return (
    <Paper sx={{ mb: 2, p: 4 }} onMouseEnter={() => { kdnrInput.current.focus() }}>
      <TextField sx={{ width: '100%' }} autoComplete="off" inputRef={kdnrInput} value={value} onChange={(evt) => { setValue(evt.target.value) }} onKeyDown={handlerKeyPress} id="outlined-basic" label="MPS-ID" variant="outlined" />
    </Paper>
  )
}


const ScannerInput = ({ setValue }) => {

  const kdnrInput = useRef(null)

  useEffect(() => {
    kdnrInput.current.focus()
  }, [])

  const [input, setInput] = useState("")

  const handlerChange = (evt) => {
    setInput(evt.target.value)
  }

  // check for "x" in begin and end (barcode)
  const checkX = (str) => {
    let hadX = false;
    if (str[0] === "x" && str[str.length - 1] === "x") {
      hadX = true;
      str = str.slice(1, -1);
    }
    return {
      input: str,
      hadX: hadX
    };
  }

  const handlerKeyPress = (evt) => {
    if (evt.keyCode === 13) {

      const { input, hadX } = checkX(evt.target.value)

      setValue(input)
      setInput("")
      kdnrInput.current.focus()
    }
  }

  return (
    <Paper sx={{ mb: 2, p: 4 }} onMouseEnter={() => { kdnrInput.current.focus() }}>
      <TextField sx={{ width: '100%' }} autoComplete="off" inputRef={kdnrInput} value={input} onChange={handlerChange} onKeyDown={handlerKeyPress} id="outlined-basic" label="MPS-ID" variant="outlined" />
    </Paper>
  )
}




const CheckoutPerson = ({ setValue, createNewProcure, updateProcure, userdata, procurements, isActivated }) => {

  const [expanded, setExpanded] = useState('none')
  const [selected, setSelected] = useState(null)

  const [selectedVollmacht, setSelectedVollmacht] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClickSelbstabholer = (evt, text) => {

    setSelected('selbstabholer')
    setValue({ abholerID: null, abholerText: text, type: 'selbstabholer' })
    setExpanded('none')
  }

  const handleClickVollmacht = () => {

    setSelected('vollmacht')

    setValue({ abholerID: selectedVollmacht.procurePersId, abholerText: selectedVollmacht.abholerText, type: 'vollmacht', vollmachtId: selectedVollmacht.procurePersId })
    setExpanded('none')
  }

  return (
    <>
      <Accordion expanded={expanded === 'selbstabholer'} onChange={handleChange('selbstabholer')} sx={{ backgroundColor: selected === 'selbstabholer' ? '#f1f8e9' : '#fff' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Box component="span" sx={{ width: '33%', flexShrink: 0, display: 'flex' }}>
            {selected === 'selbstabholer' && <CheckCircleIcon sx={{ pr: 1, color: 'success.light' }} />}
            <Typography>
              Selbstabholung
            </Typography>
          </Box>
          <Typography sx={{ color: 'text.secondary' }}>
            {selected !== 'selbstabholer' && <>Abholung nur durch den Empfänger selbst</>}
            {selected === 'selbstabholer' && <>Abholung durch {userdata.firstname + " " + userdata.lastname}</>}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography gutterBottom>
            <b>{userdata.firstname + " " + userdata.lastname}</b> muss sich durch Ausweisdokument ausweisen.
          </Typography>
          
          <Button onClick={(evt) => handleClickSelbstabholer(evt, userdata.kdnr + " " + userdata.firstname + " " + userdata.lastname)} name="selbstabholer" variant="contained" color="success">Identität überprüft</Button>
          {
            !isActivated && <>
              <Alert severity="warning" sx={{mt:2}}>
                <Typography gutterBottom>Neukunden-Erfassung erforderlich.</Typography>
                <CustomerRegistration user={{ firstname: userdata.firstname, lastname: userdata.lastname, birthdate: userdata.birthdate, kdnr: userdata.kdnr, email: userdata.email }} />
              </Alert>
            </>
          }

        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'vollmacht'} onChange={handleChange('vollmacht')} sx={{ backgroundColor: selected === 'vollmacht' ? '#f1f8e9' : '#fff' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Box component="span" sx={{ width: '33%', flexShrink: 0, display: 'flex' }}>
            {selected === 'vollmacht' && <CheckCircleIcon sx={{ pr: 1, color: 'success.light' }} />}
            <Typography>
              Abholung mit Vollmacht
            </Typography>
          </Box>
          <Typography sx={{ color: 'text.secondary' }}>
            {(selected === 'vollmacht' && !!selectedVollmacht) && <>Abholung durch {selectedVollmacht.abholerText}</>}
            {!(selected === 'vollmacht' && !!selectedVollmacht) && <>Abholung durch bevollmächtigte Personen</>}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {
            isActivated && <>
              <Procure procurements={procurements} setSelectedVollmacht={setSelectedVollmacht} updateProcure={updateProcure} createNewProcure={createNewProcure} />
              <Button disabled={!selectedVollmacht} onClick={(evt) => handleClickVollmacht(evt, userdata.kdnr + " " + userdata.firstname + " " + userdata.lastname)} name="vollmacht" variant="contained" color="success">Identität + Vollmacht überprüft</Button>
            </>
          }
          {
            !isActivated && <>
              <Alert severity="error">
                Neukunde - muss zuerst persönlich verifiziert werden!
              </Alert>
            </>
          }
        </AccordionDetails>
      </Accordion>
    </>
  )
}




const Payment = ({ setValue, shipments, userdata, creditAmount, signature }) => {

  const [expanded, setExpanded] = useState('none')
  const [selected, setSelected] = useState(null)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClick = (evt, payedCash, payedPrepaid, secondPaymentMethod) => {

    setSelected(evt.target.name)

    setValue({ paymentMethod: evt.target.name, payedCash, payedPrepaid, secondPaymentMethod })
  }

  const sum = precisionRound(shipments.reduce((p, c) => {
    return (p + (c.preis.replace(',', '.') * 100))
  }, 0.0), 12)


  const hasAccount = typeof creditAmount === 'number';

  const formattedCreditsAmount = (creditAmount / 100).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });

  const formattedPaymentAmount = (creditAmount >= sum ? 0 : (sum - creditAmount) / 100).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
  const formattedPrepaidAmount = (creditAmount >= sum ? sum / 100 : creditAmount / 100).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
  const formattedTotalAmount = (sum / 100).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });

  const UserHeader = () => {
    return (

      <Box sx={{ display: ' flex', gap: 2 }}>
        <Avatar />
        <Box>
          <Typography>{userdata.firstname} {userdata.lastname}</Typography>
          <Typography gutterBottom variant="caption">{hasAccount ? 'Guthaben: ' + formattedCreditsAmount : 'Kein Guthaben Konto'}</Typography>
        </Box>
      </Box>

    );
  }


  const defMethod = hasAccount && creditAmount > 0 ? 'guthaben' : 'barzahlung';

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [method, setMethod] = useState(defMethod);


  useEffect(() => {
    if (creditAmount >= sum && method === "guthaben") {
      handleClick({ target: { name: "guthaben" } }, 0, sum);
    } else {

      setValue(false);
    }
  }, [method, sum])


  return (
    <Card>
      <CardHeader title="Zahlung" />
      <CardContent>
        <Stepper activeStep={1} alternativeLabel sx={{ mb: 4 }}>
          <Step>
            <StepLabel>Sendungen auswählen</StepLabel>
          </Step>
          <Step>
            <StepLabel>Zahlung/Abrechnung</StepLabel>
          </Step>
          <Step>
            <StepLabel>Unterschrift</StepLabel>
          </Step>
        </Stepper>
        <Box sx={{ display: 'flex', gap: 4 }}>
          <Box sx={{ flex: 1 }}>
            <TableContainer >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Klasse</TableCell>
                    <TableCell>Anzahl</TableCell>
                    <TableCell>Preis</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(shipments.reduce((p, c) => {
                    if (c.klasse in p) {
                      p[c.klasse].rows.push(c);
                      p[c.klasse].count++;
                      p[c.klasse].sum = p[c.klasse].sum + c.priceCents;
                    } else {
                      p[c.klasse] = {
                        rows: [c],
                        count: 1,
                        name: c.klasse,
                        sum: c.priceCents,
                      }
                    }
                    return p;
                  }, {})).map((e, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>{e.name}</TableCell>
                        <TableCell>{e.count}x</TableCell>
                        <TableCell>{(e.sum / 100).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell><b>Total</b></TableCell>
                    <TableCell></TableCell>
                    <TableCell><b>{formattedTotalAmount}</b></TableCell>
                  </TableRow>
                  {method === 'guthaben' &&
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Guthaben:</TableCell>
                      <TableCell><b>{formattedPrepaidAmount}</b></TableCell>
                    </TableRow>
                  }
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Karte/Bargeld:</TableCell>
                    <TableCell><b>{method === 'guthaben' ?
                      formattedPaymentAmount : formattedTotalAmount}</b></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ flex: 1 }}>
            {settingsOpen === false &&
              <Alert severity="info" action={<IconButton onClick={() => setSettingsOpen(true)}><KeyboardArrowDown /></IconButton>}>
                <Typography variant="body2">
                  {method === 'barzahlung' ? "Bar oder Kartenzahlung" : "Guthaben verwenden"}
                </Typography>
              </Alert>
            }
            {settingsOpen === true &&
              <Alert icon={false} severity="info" action={<IconButton onClick={() => setSettingsOpen(false)}><KeyboardArrowUp /></IconButton>}>
                <RadioGroup
                  value={method}
                  onChange={(evt) => setMethod(evt.target.value)}
                >
                  <FormControlLabel value="guthaben" control={<Radio />} label="Guthaben verwenden" />
                  <FormControlLabel value="barzahlung" control={<Radio />} label="Kein Guthaben verwenden" />
                </RadioGroup>
              </Alert>
            }


            <Box sx={{ border: 'dashed 4px darkgray', backgroundColor: 'lightyellow', p: 2, borderRadius: 2, my: 2 }}>
              {(method === 'guthaben' && creditAmount >= sum) === true && <>
                <Typography variant="body2">Guthaben-Zahlung</Typography>
                <Typography variant="h5">Keine Bar-Zahlung erforderlich</Typography>
              </>}
              {(method === 'guthaben' && creditAmount >= sum) === false &&
                <>
                  <Typography variant="body2">Jetzt mit Karte oder Bargeld zu zahlen</Typography>
                  <Typography variant="h3">{method === 'guthaben' ?
                    formattedPaymentAmount : formattedTotalAmount}</Typography>
                  {method === 'guthaben' && <Typography variant="caption">Totalbetrag: {formattedTotalAmount}</Typography>}
                </>
              }
            </Box>

            {(method !== 'guthaben') && <>
              <Box sx={{ display: 'flex-row' }}>
              <Typography>Wie wurde bezahlt?</Typography>
                <Button onClick={(evt) => handleClick(evt, sum, 0)} name="barzahlung" variant="contained" color="success">Bargeld</Button>
                <Button sx={{ ml: 2 }} onClick={(evt) => handleClick(evt, sum, 0)} name="kartenzahlung" variant="contained" color="success">Kartenzahlung</Button>
                <TwintPaymentButton amount={sum} kdnr={userdata.kdnr} onSuccess={(evt) => handleClick(evt, sum, 0)} />
              </Box>
            </>}
            {(method === 'guthaben' && creditAmount < sum) && <>
              <Button onClick={(evt) => handleClick(evt, sum - creditAmount, creditAmount, 'cash')} name="guthaben" variant="contained" color="success">Bargeld</Button>
              <Button sx={{ ml: 2 }} onClick={(evt) => handleClick(evt, sum - creditAmount, creditAmount, 'card')} name="guthaben" variant="contained" color="success">Kartenzahlung</Button>
            </>}

          </Box>
          <Box sx={{ flex: 1 }}>
            {signature}
          </Box>
        </Box>
      </CardContent>

    </Card>
  )
}


const Payment2 = ({ setValue, shipments, userdata, creditAmount }) => {

  const [expanded, setExpanded] = useState('none')
  const [selected, setSelected] = useState(null)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClick = (evt, payedCash, payedPrepaid) => {

    setSelected(evt.target.name)

    setValue({ paymentMethod: evt.target.name, payedCash, payedPrepaid })
  }

  const sum = precisionRound(shipments.reduce((p, c) => {
    return (p + (c.preis.replace(',', '.') * 100))
  }, 0.0), 12)



  return (
    <>
      <Accordion expanded={expanded === 'barzahlung'} onChange={handleChange('barzahlung')} sx={{ backgroundColor: selected === 'barzahlung' ? '#f1f8e9' : '#fff' }} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Box component="span" sx={{ width: '33%', flexShrink: 0, display: 'flex' }}>
            {selected === 'barzahlung' && <CheckCircleIcon sx={{ pr: 1, color: 'success.light' }} />}
            <Typography>
              Bar / Kartenzahlung
            </Typography>
          </Box>
          <Typography sx={{ color: 'text.secondary' }}>Zahlung über klassischen Weg</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Offenen Betrag von <b>€ {(sum / 100).toFixed(2)}</b> kassieren und Unterschrift zum fertigstellen einholen
          </Typography>
          <Box sx={{ pt: 2 }}>
            <Button onClick={(evt) => handleClick(evt, sum, 0)} name="barzahlung" variant="contained" color="success">Barzahlung erfolgt</Button>
            <Button sx={{ ml: 2 }} onClick={(evt) => handleClick(evt, sum, 0)} name="barzahlung" variant="contained" color="success">Kartenzahlung erfolgt</Button>
          </Box>
        </AccordionDetails>
      </Accordion>
      {typeof creditAmount !== 'number' && <Alert severity="info">
        Kunde hat kein Guthaben-Konto
      </Alert>}
      {
        typeof creditAmount === 'number' &&
        <Accordion expanded={expanded === 'guthaben'} onChange={handleChange('guthaben')} sx={{ backgroundColor: selected === 'guthaben' ? '#f1f8e9' : '#fff' }} disableGutters>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Box component="span" sx={{ width: '33%', flexShrink: 0, display: 'flex' }}>
              {selected === 'guthaben' && <CheckCircleIcon sx={{ pr: 1, color: 'success.light' }} />}
              <Typography>
                Bezahlung mit Guthaben
              </Typography>
            </Box>
            <Typography sx={{ color: 'text.secondary' }}>Aktuelles Guthaben: € {(creditAmount / 100).toFixed(2)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {creditAmount >= sum && <Typography>Guthaben reicht aus.</Typography>}
            {creditAmount < sum && <Alert severity="error" sx={{ px: 4, py: 6 }}>Guthaben reicht nicht aus. <b>Restbetrag von € {((sum - creditAmount) / 100).toFixed(2)} kassieren</b> und Unterschrift zum fertigstellen einholen.</Alert>}
            <Box sx={{ pt: 2 }}>
              {creditAmount < sum && <Button onClick={(evt) => handleClick(evt, sum - creditAmount, creditAmount)} name="guthaben" variant="contained" color="success">Barzahlung erfolgt, Rest mit Guthaben</Button>}
              {creditAmount >= sum && <Button onClick={(evt) => handleClick(evt, 0, sum)} name="guthaben" variant="contained" color="success">Guthaben bezahlen</Button>}
            </Box>
          </AccordionDetails>
        </Accordion>
      }
    </>
  )
}

const ScannerErrors = ({ errors, setErrors }) => {

  const ErrComp = ({ text, severity, index }) => {

    const [open, setOpen] = useState(true)

    return (
      <Collapse in={open}>
        <Alert
          severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
                setErrors(errors.filter((e, i) => i !== index))
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {text}
        </Alert>
      </Collapse>
    )
  }


  const ErrCompAlreadyScanned = ({ id, index }) => ErrComp({ text: 'Die Sendung ' + id + ' wurde bereits gescannt!', severity: 'warning', index })
  const ErrCompNotInShipmentList = ({ id, index }) => ErrComp({ text: 'Die Sendung ' + id + ' steht nicht zur Abholung bereit!', severity: 'error', index })

  const resolvers = {
    'ALREADY_SCANNED': ErrCompAlreadyScanned,
    'NOT_IN_SHIPMENT_LIST': ErrCompNotInShipmentList,
  }

  return (
    <>
      {errors.map((e, i) => {
        const Element = resolvers[e.type]
        return <Element key={i} index={i} {...e} />
      })}
    </>
  )
}




const Procure = ({ procurements, setSelectedVollmacht, updateProcure, createNewProcure }) => {

  const procurementsEinzelvollmacht = procurements.filter(e => e.vollm_type === 'Einzelvollmacht')
  const procurementsDauervollmacht = procurements.filter(e => e.vollm_type === 'Dauervollmacht')

  const [displayDeactivated, setDisplayDeactivated] = useState(false)
  const [checked, setChecked] = useState(false)

  const [selectedTab, setSelectedTab] = useState(0)

  const handleCheck = (evt) => {

    setChecked(evt.target.checked ? evt.target.name : false)

    const proc = procurements.filter(e => e.ID === evt.target.name * 1)?.[0]
    const vollmacht = evt.target.checked ? {
      "procureId": proc.ID,
      "procurePersId": proc.persId,
      "abholerText": proc.persId + " " + proc.vorname + " " + proc.nachname
    } : false

    setSelectedVollmacht(vollmacht)
  }


  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={(evt, newVal) => setSelectedTab(newVal)}>
          <Tab label="Dauervollmacht" />
          <Tab label="Einzelvollmacht" />
          <Tab label="Verwaltung" />
        </Tabs>
      </Box>
      <TabPanel value={selectedTab} index={2}>
        <Card sx={{ m: 0 }}>
          <CardHeader title="Neue Vollmacht erfassen" />
          <CardContent>
            <AddProcure createNewProcure={createNewProcure} procurements={procurements} />
          </CardContent>
        </Card>
      </TabPanel>
      <TabPanel value={selectedTab} index={0}>
        <TableContainer component={Paper} sx={{ mb: 1 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Vorname</TableCell>
                <TableCell>Nachname</TableCell>
                <TableCell>Geburtsdatum</TableCell>
                <TableCell>Kommentar</TableCell>
                <TableCell>Aktiv</TableCell>
                <TableCell>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {procurementsDauervollmacht.map((e, i) => {
                const style = e.status ? {} : { color: 'text.disabled' }
                return (
                  <TableRow key={i} sx={(displayDeactivated || e.status) ? {} : { display: 'none', color: 'grey' }}>
                    <TableCell><Checkbox onClick={handleCheck} name={e.ID + ""} checked={checked === e.ID + ""} disabled={e.status === 0} /></TableCell>
                    <TableCell sx={style}>{e.vorname}</TableCell>
                    <TableCell sx={style}>{e.nachname}</TableCell>
                    <TableCell sx={style}>{e.gebdat}</TableCell>
                    <TableCell sx={style}>{e.comment}</TableCell>
                    <TableCell sx={style}>{e.status ? 'Ja' : 'Nein'}</TableCell>
                    <TableCell sx={style}><EditProcure updateProcure={updateProcure} procurement={{
                      ID: e.ID,
                      firstname: e.vorname,
                      lastname: e.nachname,
                      birthdate: e.gebdat,
                      type: e.vollm_type,
                      comment: e.comment,
                      status: e.status
                    }} /></TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ textAlign: 'center' }}>
          {!displayDeactivated && !!procurementsDauervollmacht.filter(e => e.status === 0).length && <Button variant="text" onClick={() => setDisplayDeactivated(true)}>{procurementsDauervollmacht.filter(e => e.status === 0).length} nicht aktive Vollmachten anzeigen</Button>}
          {displayDeactivated && !!procurementsDauervollmacht.filter(e => e.status === 0).length && <Button variant="text" onClick={() => setDisplayDeactivated(false)}>{procurementsDauervollmacht.filter(e => e.status === 0).length} nicht aktive Vollmachten verbergen</Button>}
        </Box>
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <TableContainer component={Paper} sx={{ mb: 1 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Vorname</TableCell>
                <TableCell>Nachname</TableCell>
                <TableCell>Geburtsdatum</TableCell>
                <TableCell>Kommentar</TableCell>
                <TableCell>Aktiv</TableCell>
                <TableCell>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {procurementsEinzelvollmacht.map((e, i) => {
                const style = e.status ? {} : { color: 'text.disabled' }
                return (
                  <TableRow key={i} sx={(displayDeactivated || e.status) ? {} : { display: 'none', color: 'grey' }}>
                    <TableCell><Checkbox onClick={handleCheck} name={e.ID + ""} checked={checked === e.ID + ""} disabled={e.status === 0} /></TableCell>
                    <TableCell sx={style}>{e.vorname}</TableCell>
                    <TableCell sx={style}>{e.nachname}</TableCell>
                    <TableCell sx={style}>{e.gebdat}</TableCell>
                    <TableCell sx={style}>{e.comment}</TableCell>
                    <TableCell sx={style}>{e.status ? 'Ja' : 'Nein'}</TableCell>
                    <TableCell sx={style}><EditProcure updateProcure={updateProcure} procurement={{
                      ID: e.ID,
                      firstname: e.vorname,
                      lastname: e.nachname,
                      birthdate: e.gebdat,
                      type: e.vollm_type,
                      comment: e.comment,
                      status: e.status
                    }} /></TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ textAlign: 'center' }}>
          {!displayDeactivated && !!procurementsEinzelvollmacht.filter(e => e.status === 0).length && <Button variant="text" onClick={() => setDisplayDeactivated(true)}>{procurementsEinzelvollmacht.filter(e => e.status === 0).length} nicht aktive Vollmachten anzeigen</Button>}
          {displayDeactivated && !!procurementsEinzelvollmacht.filter(e => e.status === 0).length && <Button variant="text" onClick={() => setDisplayDeactivated(false)}>{procurementsEinzelvollmacht.filter(e => e.status === 0).length} nicht aktive Vollmachten verbergen</Button>}
        </Box>
      </TabPanel>


    </Box>
  )
}




const Notification = (x) => {

  const { id, text, status, popup, history, notificationsPopup, setNotificationsPopup, updateNotification, userdata } = x

  const [loading, setLoading] = useState(false)

  const [isDone, setIsDone] = useState(!!status)
  const [isPopUp, setIsPopUp] = useState(!!popup)
  const [comment, setComment] = useState("")

  const handleClose = () => {
    setNotificationsPopup(notificationsPopup.filter(e => e.id !== id))
  }

  const handleSaveNotification = () => {

    const notificationUpdate = {
      notificationId: id,
      status: isDone ? 1 : 0,
      popup: isPopUp ? 1 : 0,
      comment
    }
    setLoading(true)
    updateNotification(notificationUpdate)
  }

  return (
    <Alert
      sx={{ my: 2 }}
      severity="warning"
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={handleClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      {text}

      {text === 'NEUKUNDE: Bitte Stammblatt drucken und unterzeichnen lassen!' && <Box sx={{mt:2}}>
          <CustomerRegistration user={{ firstname: userdata.firstname, lastname: userdata.lastname, birthdate: userdata.birthdate, kdnr: userdata.kdnr, email: userdata.email }} />
      </Box>}

      {text !== 'NEUKUNDE: Bitte Stammblatt drucken und unterzeichnen lassen!' && <>
        <Box>
          <Checkbox onClick={(evt) => setIsDone(evt.target.checked)} checked={isDone} /> Erledigt
        </Box>
        <Box>
          <Checkbox onClick={(evt) => setIsPopUp(evt.target.checked)} checked={isPopUp} /> Popup
        </Box>
        <Box>
          <TextField onChange={(evt) => setComment(evt.target.value)} sx={{ backgroundColor: 'white', width: '400px' }} multiline placeholder="Kommentar" value={comment} />
        </Box>
        <Button sx={{ mt: 2 }} disabled={loading || comment.length < 2} variant="outlined" onClick={handleSaveNotification}>
          {loading && <>
            Speichern
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                color: 'blue',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          </>
          }
          {!loading && <span>Speichern</span>}

        </Button>
      </>}
    </Alert>
  )
}



const HistorieButton = ({ getOutlistHistory, outlistHistory: rawOutlistHistory, getAbholbelegFile, userdata, creditAmount, notifications, updateNotification, customerTransactions }) => {

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setLoading(true)
      getOutlistHistory()
    }
  }, [open])

  useEffect(() => {
    setLoading(false)
  }, [rawOutlistHistory])

  const outlistHistory = rawOutlistHistory.reduce((p, c) => {
    const matches = p.filter(e => e.outlistId === c.outlistId)
    const existingShpipments = (matches.length > 0)
      ? matches[0].shipments
      : []

    return [
      ...p.filter(e => e.outlistId !== c.outlistId),
      {
        outlistId: c.outlistId,
        shipments: [...existingShpipments, c]
      }
    ]

  }, [])

  return (
    <>
      <Button onClick={() => { if (!loading) setOpen(!open) }}>Historie</Button>

      <Drawer open={open} sx={{ overflow: 'scroll' }} anchor="right" onClose={() => setOpen(false)}>
        <Container disableGutters={true} sx={{ p: 4, minWidth: '800px' }}>
          <Grid container>
            <Grid item>
              <Card sx={{ mt: 2 }}>
                <CardHeader title="Abholungen" />
                <CardContent>
                  {loading && <><LinearProgress /></>}



                  {false && [
                    {
                      field: 'id',
                      headerName: 'MPS ID',
                      width: 140,
                    },
                    {
                      field: 'klasse',
                      headerName: 'Klasse',
                      width: 140,
                    },
                    {
                      field: 'absender',
                      headerName: 'Absender',
                      width: 140,
                    },
                    {
                      field: 'abholdatum2',
                      headerName: 'Abholdatum',
                      width: 140,
                    },
                    {
                      field: 'abholerText',
                      headerName: 'Abholer',
                      width: 140,
                    },
                    {
                      field: 'dataname',
                      headerName: 'Abholbeleg',
                      width: 140,
                    },

                  ]}

                  {outlistHistory.map((e, i) => {

                    const shipment = e.shipments[0]

                    const {
                      abholerText,
                      dataname,
                      abholdatum2,
                      created,
                    } = shipment

                    return (
                      <Box key={i}>
                        <b>{abholdatum2}</b> ({e.outlistId})<br />
                        Abgeholte Sendungen: {e.shipments.length} von {abholerText}<br />
                        {dataname.match(/^v2\/2.+/) !== null && <>{new Date(created).toLocaleString('de-DE')}<br /></>}
                        {dataname.match(/^v2\/2.+/) !== null && <><Button variant="outlined" size="small" onClick={() => getAbholbelegFile(dataname)}>Abholbeleg</Button><br /></>}
                        <br />
                      </Box>
                    )
                  })}

                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Drawer>
    </>
  )
}


const StammdatenblattButton = ({
  posConfig,
  getStammdatenblattPDF,
  getKundenkartePDF,
  updateStammdaten,
  userdata,
  creditAmount,
  notifications,
  updateNotification,
  customerTransactions,
  addFunds,
  calculateBonus,
  respCalculateBonus,
  respAddFunds,
  respCustomerCouponCodes,
  getCustomerTransactions,
  getCustomerCouponCodes
}) => {

  const [open, setOpen] = useState(false);
  const [openGuthabenDetails, setOpenGuthabenDetails] = useState(false);
  const [openGutscheineDetails, setOpenGutscheineDetails] = useState(false);
  const [openAuslagenDetails, setOpenAuslagenDetails] = useState(false);
  const [loading, setLoading] = useState(false);

  const [localUserdata, setLocalUserdata] = useState({})

  useEffect(() => {
    setLoading(false)
    setLocalUserdata(userdata)
  }, [userdata])

  const [openStammdaten, setOpenStammdaten] = useState(false);

  return (
    <>
      <Button onClick={() => { if (!loading) setOpen(!open) }}>Stammdaten</Button>

      <Drawer open={open} sx={{ overflow: 'scroll', '.MuiDrawer-paper': { backgroundColor: '#f0f0f0' } }} anchor="right" onClose={() => setOpen(false)}>
        <Container disableGutters={true} sx={{ p: 2, gap: 2, minWidth: '1200px' }}>
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <Paper sx={{ p: 2 }}>
                <Typography sx={{ mb: 3 }} variant="h5">Stammdaten</Typography>
                <Box sx={{ display: openStammdaten ? 'none' : 'block' }}>
                  <Typography variant="h6" gutterBottom>{localUserdata.kdnr}</Typography>
                  <Typography variant="body2" gutterBottom>{localUserdata.firstname} {localUserdata.lastname}</Typography>
                  <Typography variant="caption">Anschrift</Typography>
                  <Typography variant="body2">{localUserdata.address}</Typography>
                  <Typography variant="body2" gutterBottom>{localUserdata.zip} {localUserdata.place}</Typography>
                  <Typography variant="caption">Privat</Typography>
                  <Typography variant="body2" gutterBottom>{localUserdata.phone_private}</Typography>
                  <Typography variant="caption">Mobile</Typography>
                  <Typography variant="body2" gutterBottom>{localUserdata.phone_mobile}</Typography>
                  <Typography variant="caption">Email</Typography>
                  <Typography variant="body2" gutterBottom>{localUserdata.email}</Typography>
                  <Button sx={{ mt: 2 }} onClick={() => setOpenStammdaten(true)} size="small" variant="outlined">Bearbeiten</Button>
                </Box>
                <Box sx={{ display: !openStammdaten ? 'none' : 'block' }}>
                  <Box sx={{ mb: 2 }}>
                    <TextField autoComplete="false" label="Kundennummer" size="small" disabled={true} sx={{ width: '100%' }} value={localUserdata.kdnr} />
                  </Box>
                  <FormControl fullWidth margin="normal" sx={{ mb: 2 }}>
                    <InputLabel id="type-select-label">Anrede</InputLabel>
                    <Select
                      labelId="salutation-select-label"
                      id="salutation-select"
                      value={localUserdata.salutation}
                      label="Geschlecht"
                      size="small"
                      onChange={(evt) => { setLocalUserdata({ ...localUserdata, salutation: evt.target.value }) }}
                    >
                      <MenuItem value="Bitte auswählen">Bitte auswählen...</MenuItem>
                      <MenuItem value="Herr">Herr</MenuItem>
                      <MenuItem value="Frau">Frau</MenuItem>
                      <MenuItem value="Divers">Divers</MenuItem>
                    </Select>
                  </FormControl>
                  <Box sx={{ mb: 2 }}>
                    <TextField autoComplete="false" label="Vorname" size="small" sx={{ width: '100%' }} onChange={(evt) => { setLocalUserdata({ ...localUserdata, firstname: evt.target.value }) }} value={localUserdata.firstname} />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <TextField autoComplete="false" label="Nachname" size="small" sx={{ width: '100%' }} onChange={(evt) => { setLocalUserdata({ ...localUserdata, lastname: evt.target.value }) }} value={localUserdata.lastname} />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <TextField autoComplete="false" label="Adresse" size="small" sx={{ width: '100%' }} onChange={(evt) => { setLocalUserdata({ ...localUserdata, address: evt.target.value }) }} value={localUserdata.address} />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <TextField autoComplete="false" label="PLZ" size="small" sx={{ width: '33.333%' }} onChange={(evt) => { setLocalUserdata({ ...localUserdata, zip: evt.target.value }) }} value={localUserdata.zip} />
                    <TextField autoComplete="false" label="Ort" size="small" sx={{ width: '66.666%' }} onChange={(evt) => { setLocalUserdata({ ...localUserdata, place: evt.target.value }) }} value={localUserdata.place} />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <TextField disabled={true} autoComplete="false" label="Email" size="small" sx={{ width: '100%' }} onChange={(evt) => { setLocalUserdata({ ...localUserdata, email: evt.target.value }) }} value={localUserdata.email} />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <TextField autoComplete="false" label="Phone Private" size="small" sx={{ width: '100%' }} onChange={(evt) => { setLocalUserdata({ ...localUserdata, phone_private: evt.target.value }) }} value={localUserdata.phone_private} />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <TextField autoComplete="false" label="Phone Mobile" size="small" sx={{ width: '100%' }} onChange={(evt) => { setLocalUserdata({ ...localUserdata, phone_mobile: evt.target.value }) }} value={localUserdata.phone_mobile} />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    {false && <TextField autoComplete="false" placeholder="" label="Geburtsdatum" size="small" sx={{ width: '100%' }} onChange={(evt) => { setLocalUserdata({ ...localUserdata, birthdate: evt.target.value }) }} value={localUserdata.birthdate} />}
                  </Box>
                  <Button size="small" disabled={loading} variant="contained" onClick={() => { setLoading(true); updateStammdaten(localUserdata); setOpenStammdaten(false); }}>Speichern</Button>
                </Box>

              </Paper>
              <Box sx={{ display: 'flex', gap: 2, py: 2 }}>
                <Button startIcon={<Print />} sx={{ backgroundColor: 'white' }} size="small" color="success" disabled={loading} variant="outlined" onClick={getStammdatenblattPDF}>Stammdatenblatt</Button>
                <Button startIcon={<Print />} sx={{ backgroundColor: 'white' }} size="small" color="success" disabled={loading} onClick={getKundenkartePDF} variant="outlined">Kundenkarte</Button>
              </Box>
            </Grid>
            <Grid item sm={8}>
              <Card >
                <CardActionArea onClick={() => setOpenGuthabenDetails(!openGuthabenDetails)}>
                  <CardHeader subheader="Guthaben" />
                  <CardContent>
                    {isNaN(creditAmount) && <><Alert severity="info">Kein Guthaben-Konto angelegt.</Alert></>}
                    {isNaN(creditAmount) === false && <>
                      <Box sx={{ width: '120px', display: 'flex', alignItems: 'center', flexWrap: 'wrap', p: 1, mr: 2, border: 'solid 1px darkgray', background: creditAmount < 100 ? '#ffccbc' : '#dcedc8' }} component="span">
                        <AccountBalanceWalletIcon sx={{ mr: 1 }} />
                        {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((creditAmount / 100))}
                      </Box>
                    </>}
                  </CardContent>
                </CardActionArea>
                <Collapse in={openGuthabenDetails}>
                  <CardContent>
                    <CreditManagementLight getCustomerTransactions={getCustomerTransactions} addFunds={addFunds} calculateBonus={calculateBonus} respCalculateBonus={respCalculateBonus} respAddFunds={respAddFunds} />
                    <CustomerTransactions customerTransactions={customerTransactions} />
                  </CardContent>
                </Collapse>
              </Card>

              <Card sx={{ mt: 2 }}>
                <CardActionArea onClick={() => {
                  setOpenAuslagenDetails(!openAuslagenDetails);
                }}>
                  <CardHeader subheader="Auslagen" />
                </CardActionArea>
                <Collapse in={openAuslagenDetails}>
                  <CardContent>
                    <AuslagenWidget posConfig={posConfig} kdnr={userdata.kdnr} />
                  </CardContent>
                </Collapse>
              </Card>



              <Card sx={{ mt: 2 }}>
                <CardHeader subheader="Notizen" />
                <CardContent>
                  <NotificationEditor notifications={notifications} updateNotification={updateNotification} />
                </CardContent>
              </Card>

              <Card sx={{ mt: 2 }}>
                <CardActionArea onClick={() => {
                  if (!openGuthabenDetails) {
                    getCustomerCouponCodes();
                  }
                  setOpenGutscheineDetails(!openGutscheineDetails);
                }}>
                  <CardHeader subheader="Gutscheine" />
                </CardActionArea>
                <Collapse in={openGutscheineDetails}>
                  <CardContent>
                    <CustomerCouponCodes getCustomerCouponCodes={getCustomerCouponCodes} respCustomerCouponCodes={respCustomerCouponCodes} />
                  </CardContent>
                </Collapse>
              </Card>

            </Grid>
          </Grid>


        </Container>
      </Drawer>
    </>
  )
}


const NotificationEditor = ({ notifications: inputNotifications, notification: editNotification, disabled, updateNotification }) => {

  const newNotification = {
    comment: '',
    popup: false,
    status: 0,
    notificationId: false
  }

  const [notifications, setNotifications] = useState([]);

  const [notification, setNotification] = useState(editNotification || newNotification)

  const [selectedNotification, setSelectedNotification] = useState(null)

  const [loading, setLoading] = useState(false)
  const [showView, setShowView] = useState('list')


  useEffect(() => {
    setSelectedNotification(null)
    setNotification(newNotification)

    const temp = inputNotifications.map((e) => {
      const t = e.erstelltam.split('.')
      return {
        ...e,
        timestamp: (t[2] + t[1] + t[0]) * 1
      }
    })

    setNotifications(temp)
    setLoading(false)
  }, [inputNotifications])


  const columns = [
    {
      field: 'erstelltam',
      headerName: 'Erstellt am',
      width: 140,
    },
    {
      field: 'erstelltvon',
      headerName: 'Erstellt von',

      width: 140,
      hide: true
    },
    {
      field: 'erledigtam',
      headerName: 'Erledigt am',
      width: 140,
      renderCell: ({ value }) => {
        if (!value) return <b>Offen!</b>
        return value
      }
    },
    {
      field: 'erledigtvon',
      headerName: 'Erledigt von',
      width: 140,
      hide: true
    },
    {
      field: 'text',
      headerName: 'Notiz',
      width: 420,
    },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      width: 120,
      hide: true
    },
  ];

  const severity = (selectedNotification?.erledigtam)
    ? 'success'
    : (selectedNotification?.popup)
      ? 'error'
      : 'warning'

  return (
    <>


      <Card sx={{ mb: 2, minHeight: '300px', p: 2 }} variant="outlined">
        {showView === 'list' && <>
          {!!selectedNotification &&
            <Box>
              <Alert severity={severity} sx={{ mb: 2 }}>
                <Typography fontFamily="Courier">{selectedNotification.text}</Typography>
              </Alert>
              <Divider sx={{ my: 2 }} />
              <Typography variant="caption">Historie:</Typography>
              <Box sx={{ whiteSpace: 'pre-line' }}>
                Erstellt am {selectedNotification.erstelltam} von {selectedNotification.erstelltvon}<br />
                {selectedNotification.history}
              </Box>
              {selectedNotification.erledigtam && <>Erledigt am {selectedNotification.erledigtam} von {selectedNotification.erledigtvon}</>}
            </Box>
          }
          {selectedNotification === null &&
            <Alert severity="info">Notiz auswählen oder <Button variant="text" color="success" disabled={disabled || loading} onClick={(e) => { setShowView('create') }}>Notiz erstellen</Button><br /><br /></Alert>
          }
        </>}

        {showView === 'create' &&
          <Box sx={{ display: 'block' }}>
            <FormControl sx={{ width: '100%', mb: 2 }}>
              <TextField label="Text" multiline minRows={2} onChange={(evt) => { setNotification({ ...notification, comment: evt.target.value }) }} value={notification.comment} />
            </FormControl>
            <FormControl>
              <FormControlLabel label="Popup anzeigen" control={<Switch title="Popup" checked={notification.popup} onChange={(evt) => { setNotification({ ...notification, popup: evt.target.checked }) }} />} />
            </FormControl>
            <Button onClick={() => { updateNotification(notification); setLoading(true); setShowView('list') }} variant="outlined" disabled={loading}>{notification.notificationId === false ? 'Erstellen' : 'Änderung speichern'}</Button>
            <Button sx={{ ml: 2 }} color="warning" onClick={() => { setNotification(newNotification); setShowView('list') }} variant="outlined">Abbrechen</Button>
          </Box>
        }
      </Card>

      <Box sx={{ height: 320, width: '100%', mt: 4 }}>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'timestamp', sort: 'desc' }],
            },
          }}
          rows={notifications || []}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          onSelectionModelChange={(evt) => {
            const id = evt.length > 0 ? evt[0] : null
            console.log(id)
            console.log({ id })
            if (id) {
              setSelectedNotification(notifications.filter(e => e.id === id)[0])
            } else {
              setSelectedNotification(null)
            }
          }}
          getRowId={(row) => row.id}
        />
      </Box>

      <Button sx={{ mt: 2 }} color="success" disabled={disabled || loading} onClick={() => { setShowView('create') }} variant="outlined">Neue Notiz</Button>





    </>
  )
}

const AddProcure = ({ createNewProcure, procurements }) => {

  const [open, setOpen] = useState(false);
  const [idChecked, setIdChecked] = useState();
  const [valid, setValid] = useState();

  const emptyProcure = {
    firstname: "",
    lastname: "",
    birthdate: "",
    type: "",
    comment: "",
    status: 0
  }

  const [procure, setProcure] = useState(emptyProcure)

  useEffect(() => {
    setOpen(false)
    setProcure(emptyProcure)
  }, [procurements])

  useEffect(() => {
    let validated = true;
    if (procure.type.length < 2) validated = false;
    if (procure.firstname.length < 2) validated = false;
    if (procure.lastname.length < 2) validated = false;
    if (procure.birthdate.match(/\d\d\.\d\d\.\d\d\d\d/) === null) validated = false;
    if (validated) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [procure])

  return (
    <Box>
      <Modal open={open} >
        <Container disableGutters={true}>
          <Card sx={{ p: 2, mt: 4, width: '100%' }}>
            <Grid container spacing={4}>
              <Grid item sm={4}>
                <FormControl fullWidth margin="normal">
                  <TextField autoComplete="false" size="small" label="Vorname" onChange={(evt) => setProcure({ ...procure, firstname: evt.target.value })} value={procure.firstname} />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <TextField autoComplete="false" size="small" label="Nachname" onChange={(evt) => setProcure({ ...procure, lastname: evt.target.value })} value={procure.lastname} />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <TextField autoComplete="false" size="small" label="Geburtsdatum" placeholder="TT.MM.JJJJ" onChange={(evt) => setProcure({ ...procure, birthdate: evt.target.value })} value={procure.birthdate} />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="type-select-label">Vollmacht Typ</InputLabel>
                  <Select
                    labelId="type-select-label"
                    id="type-select"
                    value={procure.type}
                    label="Typ"
                    size="small"
                    onChange={(evt) => { setProcure({ ...procure, type: evt.target.value }) }}
                  >
                    <MenuItem value="Einzelvollmacht">Einzelvollmacht</MenuItem>
                    <MenuItem value="Dauervollmacht">Dauervollmacht</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="status-select-label">Status</InputLabel>
                  <Select
                    size="small"
                    labelId="status-select-label"
                    id="status-select"
                    value={procure.status}
                    label="Status"
                    onChange={(evt) => { setProcure({ ...procure, status: evt.target.value }) }}
                  >
                    <MenuItem value={0}>Nicht aktiv</MenuItem>
                    <MenuItem value={1}>Aktiv</MenuItem>
                  </Select>
                </FormControl>
                <FormControlLabel control={<Checkbox onChange={(evt) => setIdChecked(evt.target.checked)} />} label="Ausweisdokument wurde überprüft." />
                <Box sx={{ mt: 2 }}>
                  <Button disabled={!valid || !idChecked} onClick={() => createNewProcure(procure)}>Speichern</Button>
                  <Button sx={{ ml: 2 }} onClick={() => { setOpen(false); setProcure(emptyProcure) }} variant="outlined">Abrechen</Button>
                </Box>
              </Grid>
              <Grid item sm={8}>

              </Grid>
            </Grid>
          </Card>
        </Container>
      </Modal>
      <Button variant="contained" onClick={() => setOpen(true)}>Vollmacht erfassen</Button>
    </Box>
  )
}


const EditProcure = ({ updateProcure, procurement }) => {

  const [open, setOpen] = useState(false);

  const [procure, setProcure] = useState(procurement)

  useEffect(() => {
    setProcure(procurement)
    setOpen(false)
  }, [procurement])


  return (
    <Box>
      <Modal open={open} >
        <Container disableGutters={true}>
          <Card sx={{ p: 2, mt: 4, width: '100%' }}>
            <Grid container spacing={4}>
              <Grid item sm={4}>
                <FormControl fullWidth margin="normal">
                  <TextField disabled={true} autoComplete="false" size="small" label="Vorname" value={procure.firstname} />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <TextField disabled={true} autoComplete="false" size="small" label="Nachname" value={procure.lastname} />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <TextField disabled={true} autoComplete="false" size="small" label="Geburtsdatum" placeholder="TT.MM.JJJJ" value={procure.birthdate} />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="type-select-label">Vollmacht Typ</InputLabel>
                  <Select
                    labelId="type-select-label"
                    id="type-select"
                    value={procure.type}
                    label="Age"
                    disabled={true}
                  >
                    <MenuItem value="Einzelvollmacht">Einzelvollmacht</MenuItem>
                    <MenuItem value="Dauervollmacht">Dauervollmacht</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="status-select-label">Status</InputLabel>
                  <Select
                    labelId="status-select-label"
                    id="status-select"
                    value={procure.status}
                    label="Age"
                    onChange={(evt) => { setProcure({ ...procure, status: evt.target.value }) }}
                  >
                    <MenuItem value={0}>Nicht aktiv</MenuItem>
                    <MenuItem value={1}>Aktiv</MenuItem>
                  </Select>
                </FormControl>
                <Box sx={{ mt: 2 }}>
                  <Button variant="contained" onClick={() => updateProcure(procure)}>Speichern</Button>
                  <Button sx={{ ml: 2 }} onClick={() => { setOpen(false) }} variant="outlined">Abrechen</Button>
                </Box>
              </Grid>
              <Grid item sm={8}>

              </Grid>
            </Grid>
          </Card>
        </Container>
      </Modal>
      <Button onClick={() => setOpen(true)} variant="outlined">Bearbeiten</Button>
    </Box>
  )
}


const CustomerCouponCodes = ({ getCustomerCouponCodes, respCustomerCouponCodes }) => {

  console.log({ respCustomerCouponCodes })

  if (!respCustomerCouponCodes) {
    return;
  }
  //const kwk = respCustomerCouponCodes.kwk.map(e=>({...e, code: encodeNumber(e.kdnr*1)}));
  const coupons = respCustomerCouponCodes.coupons;

  return (
    <Box>
      {/* 
        <Typography>Empfohlene Kunden:</Typography>
        {kwk.map((e,i)=>{
          return (<Box key={i}><Link to={"/checkout/"+e.kdnr}>{e.kdnr}</Link> {e.redeemedTimestamp}</Box>);
        })}
      */}
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Datum</TableCell>
              <TableCell>Wert</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Kommentar</TableCell>
            </TableRow>

            {coupons.sort((a, b) => b.redeemedTimestamp - a.redeemedTimestamp).map((e, i) => {
              return (<SingleCoupon coupon={e} key={i} />);
            })}

            {!coupons.length && <TableRow>
              <TableCell colSpan={5}>Keine Gutscheine</TableCell>
            </TableRow>}

          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}


const SingleCoupon = ({ coupon }) => {

  //if(coupon.type !== 'FIXED') return;

  if (coupon.code.indexOf("NEUKUNDE") === 0) {
    coupon.code = "NEUKUNDE";
    coupon.bonus = 500;
    coupon.coupontype = "Neukunde";
  }

  if (!coupon.coupontype) {
    coupon.coupontype = "generic";
  };

  if (coupon.code.indexOf("GEWORBEN") === 0) {
    coupon.code = 'MPSE-' + encodeNumber(coupon.recommendedby);
    coupon.bonus = 500;
  }

  const iconResolver = (coupontype) => {
    switch (coupontype) {
      case "Reward-KwK-Version-1": return <Face />
      case "KwK-Version-1": return <FaceRetouchingNatural />
      case "Neukunde": return <Redeem />
      default: return undefined;
    }
  }

  const labelResolver = (coupontype) => {
    switch (coupontype) {
      case "Reward-KwK-Version-1": return 'Erfolgreicher Kunden-werben-Kunden VermittlungsGutschein'
      case "KwK-Version-1": return 'Kunden werben Kunden Willkommens-Gutschein'
      case "Neukunde": return 'Neukunden Gutschein'
      default: return 'Gutschein';
    }
  }

  return (
    <TableRow>
      <TableCell><Tooltip title={labelResolver(coupon.coupontype)}><Chip icon={iconResolver(coupon.coupontype)} label={coupon.code} variant="outlined" /></Tooltip></TableCell>
      <TableCell><small>{!!coupon.redeemedTimestamp ? new Date(coupon.redeemedTimestamp)?.toLocaleString('de', { timeZone: 'Europe/Berlin', timeZoneName: 'short' }) : "Nicht eingelöst"}</small></TableCell>
      <TableCell>{(coupon.bonus * 1 / 100).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</TableCell>
      <TableCell>{!!coupon.isRedeemed ? <CheckCircleOutline color="success" /> : <HelpOutlineOutlined />}</TableCell>
      <TableCell>{coupon.comment}</TableCell>
    </TableRow>
  );
}


const precisionRound = (number, precision) => {
  var factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
}



function encodeNumber(number) {
  const charset = '23456789ABCDEFGHJKLMNPQRSTUVWXYZ';
  let shift = 18;
  let encoded = '';
  const base = charset.length;
  let currentNumber = number;

  // Convert number to the specified base
  while (currentNumber > 0) {
    const index = currentNumber % base;
    currentNumber = Math.floor(currentNumber / base);
    encoded = charset[index] + encoded;
  }

  // Apply shift cipher
  let shifted = '';
  for (let i = 0; i < encoded.length; i++) {
    const charIndex = charset.indexOf(encoded[i]);
    const shiftedIndex = (charIndex + shift) % base;
    shifted += charset[shiftedIndex];
  }

  return shifted;
}


