import { Alert, Box, Button, Card, CardContent, CircularProgress, Dialog } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useCookies } from 'react-cookie';
import useWebSocket from 'react-use-websocket';


const socketUrl = 'wss://brmm0q8jaa.execute-api.eu-central-1.amazonaws.com/production';


const TwintPaymentButton = ({amount, kdnr, onSuccess}) => {

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cookies] = useCookies(['secret', 'station', 'staff']);
    const [paymentId, setPaymentId] = useState();

    const [alert, setAlert] = useState(false);

    const { sendMessage, readyState } = useWebSocket(socketUrl,
        {
          queryParams: {
            station: cookies.station,
            secret: cookies.secret,
            staff: cookies.staff
          },
          onMessage: (message) => handleWebsocketEvent(message),
          shouldReconnect: (_closeEvent) => true,
          retryOnError: true,
          onClose: () => console.log('closing'),
          onError: (err) => console.log('error', err),
          onReconnectStop: (err) => console.log('recon stop', err),
        }
    );
    const requestPayment = (data) => {
        sendMessage(JSON.stringify({ task: 'requestTwintPayment', station: cookies.station, staff: cookies.staff, amount, kdnr }))
    }

    const validatePayment = () => {
        sendMessage(JSON.stringify({ task: 'validateTwintPayment', station: cookies.station, staff: cookies.staff, paymentId }))
    }


    const handleWebsocketEvent = (lastMessage) => {

        const msg = JSON.parse(lastMessage.data)
        console.log(msg)

        if (msg.task === 'respRequestTwintPayment') {
            setPaymentId(msg.paymentId)
        }
        if (msg.task === 'twintPaymentResponseAvailable') {
           validatePayment();
        }
        if(msg.task === 'respValidateTwintPayment'){
            if(["canceled", "failed", "expired"].includes(msg.payment_status)){
                setAlert('Zahlung fehlgeschlagen - Id: '+paymentId);
                setLoading(false);
            }
            if(["paid"].includes(msg.payment_status)){
                setOpen(false);
                setLoading(false);
                setPaymentId();
                onSuccess({
                    target:{
                        name: 'twintpos'
                    }
                });
            }
            if(["pending", "open", null].includes(msg.payment_status)){
                const myTimeout = setTimeout(()=>{
                    validatePayment();
                }, 1000);
            }
        }
        
    }

    const handlePayment = () => {
        setOpen(true);
        setLoading(true);
        setAlert();
        setPaymentId();
        requestPayment();
    };

    return (
        <Fragment>
            <Button sx={{ ml: 2 }} variant="contained" color="success" onClick={handlePayment}>Twint</Button>
            <Dialog open={open} onClose={()=>setOpen(false)}>
                <Card sx={{width: '500px'}}>
                    <CardContent>
                        {!!alert && <Alert severity='error'>{alert}</Alert>}
                        {!!loading && <Box sx={{ textAlign: 'center'}}>Warten auf Zahlung {amount/100} € - Signatur Pad<br /><br /><CircularProgress /></Box>}
                    </CardContent>
                </Card>
            </Dialog>
        </Fragment>
    );
};

export default TwintPaymentButton;